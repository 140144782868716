@use "@angular/material" as mat;
@use "variables" as v;

/* material theming */

$md-material: (
  50: #e0f1f7,
  100: #b3ddea,
  200: #80c6dd,
  300: #4dafcf,
  400: #269dc4,
  500: #008cba,
  600: #0084b3,
  700: #0079ab,
  800: #006fa3,
  900: #005c94,
  A100: #bfe3ff,
  A200: #8ccdff,
  A400: #59b7ff,
  A700: #40abff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$material-primary: mat.m2-define-palette($md-material);
$material-accent: mat.m2-define-palette($md-material, 100, 900, A100);
$material-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $material-primary,
      accent: $material-accent
    ),
    density: 0
  )
);

$custom-typography: mat.m2-define-typography-config(
  $font-family: "Inter-self, sans-serif",
  $headline-5: mat.m2-define-typography-level(20px, 32px, 800),
  $headline-6: mat.m2-define-typography-level(16px, 22px, 800),
  $subtitle-1: mat.m2-define-typography-level(15px, 24px, 800),
  $subtitle-2: mat.m2-define-typography-level(16px, 24px, 800),
  $body-1: mat.m2-define-typography-level(14px, 20px, 400, $letter-spacing: normal),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400, $letter-spacing: normal),
  $button: mat.m2-define-typography-level(14px, 20px, 500, $letter-spacing: normal)
);

$custom-typography-desktop: mat.m2-define-typography-config(
  $font-family: "Inter-self, sans-serif",
  $headline-5: mat.m2-define-typography-level(23px, 31px, 800),
  $headline-6: mat.m2-define-typography-level(18px, 25px, 800),
  $subtitle-1: mat.m2-define-typography-level(16px, 24px, 800),
  $subtitle-2: mat.m2-define-typography-level(18px, 24px, 800),
  $body-1: mat.m2-define-typography-level(15px, 22px, 400, $letter-spacing: normal),
  $body-2: mat.m2-define-typography-level(15px, 22px, 400, $letter-spacing: normal),
  $button: mat.m2-define-typography-level(15px, 22px, 400, $letter-spacing: normal)
);

@include mat.all-component-typographies($custom-typography);

@media (min-width: v.$tablet) {
  @include mat.all-component-typographies($custom-typography-desktop);
}

@include mat.core-theme($material-theme);
@include mat.elevation-classes();
@include mat.app-background();
@include mat.autocomplete-theme($material-theme);
@include mat.button-theme($material-theme);
@include mat.card-theme($material-theme);
@include mat.checkbox-theme($material-theme);
@include mat.chips-theme($material-theme);
@include mat.table-theme($material-theme);
@include mat.sort-theme($material-theme);
@include mat.dialog-theme($material-theme);
@include mat.expansion-theme($material-theme);
@include mat.input-theme($material-theme);
@include mat.list-theme($material-theme);
@include mat.menu-theme($material-theme);
@include mat.radio-theme($material-theme);
@include mat.select-theme($material-theme);
@include mat.slider-theme($material-theme);
@include mat.slide-toggle-theme($material-theme);
@include mat.tabs-theme($material-theme);
@include mat.tooltip-theme($material-theme);
@include mat.snack-bar-theme($material-theme);
@include mat.form-field-theme($material-theme);
@include mat.progress-bar-theme($material-theme);
@include mat.tree-theme($material-theme);
@include mat.button-toggle-theme($material-theme);

//only for admin
@include mat.paginator-theme($material-theme);
@include mat.datepicker-theme($material-theme);
