$primary: #008cba;
$highlight: #01a7de;
$text: #424242;
$plus: #83c77c;
$pro: #6ab0c7;
$enterprise: #c7b86a;
$border: #eeeeee;
$border-dark: #d1d1d1;
$warning: #ec6767;
$sales: #00ba53;
$sales-hover: #009e47;
:root {
    --header-height: 58px;
    --banner-height: 40px;
    --header-without-banner-height: 58px;
}
$scrollheight: calc(100% - var(--header-height));
$background: #f7f7f7;
$mobile: 440px;
$tablet: 787px;
$desktop: 981px;
$large-screen: 1200px;
$larger-screen: 1450px;
$light-text: #929292;
$radius: 15px;
$boxshadow:
    0 2px 4px rgba(0, 0, 0, 0.2),
    0 -1px 0px rgba(0, 0, 0, 0.02);

$standard-padding: 20px;

/* 
Example regex to use in VScode to find all class names with $z-level-60 z-index:
\.([a-zA-Z0-9_-]+)\s*\{[^{}]*?z-index\s*:\s*\$z-level-60\s*;[^{}]*?\}  
*/
$z-level-0: 1000;
$z-level-10: 1010;
$z-level-20: 1020;
$z-level-30: 1030;
$z-level-40: 1040;
$z-level-50: 1050;
$z-level-60: 1060;
$z-level-70: 1070;
$z-level-80: 1080;
$z-level-90: 1090;
$z-level-100: 1100;
$z-above: 1;
$z-below: -1;
